// GENERATED BY generateImg.js script
import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

// const gql = graphql`
//   query {
//     file(relativePath: { eq: "win-a-trip.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1400) {
//           ...GatsbyImageSharpFluid_noBase64
//         }
//       }
//     }
//   }
// `;

const gql = graphql`
  query {
    file(relativePath: { eq: "win-a-trip-india.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Image = ({ alt }) => (
  <StaticQuery
    query={gql}
    render={data => <Img fluid={data.file.childImageSharp.fluid} alt={alt} />}
  />
);

export default Image;
