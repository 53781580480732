import React from 'react';

import './index.css';
import { winATripSave } from '../../../services/apiService';
import Confetti from 'react-dom-confetti';
import { Link } from 'gatsby';

const FormToWin = ({ configurableHeadingText }) => {
  const [values, setValue] = React.useState({
    name: {
      label: 'User Name',
      value: '',
      hasError: true,
      errorText: 'Please provide a name',
      validation: val => val !== ''
    },
    email: {
      label: 'Email',
      value: '',
      hasError: true,
      errorText: 'Please provide a correct email address',
      validation: val => handleInputChange(val)
    },
    phone: {
      label: 'Phone',
      value: '',
      hasError: true,
      errorText: 'Please provide a correct phone number',
      validation: val => val !== ''
    },
    originAirport: {
      label: 'Preferred origin airport',
      value: '',
      hasError: true,
      errorText: 'Please provide a origin Airport',
      validation: val => val !== ''
    },
    tripsInYear: {
      label: 'Avg. trips in a year',
      value: '',
      hasError: true,
      errorText: 'Please provide a trips in a year',
      validation: val => val !== ''
    },
    isShowError: false
  });
  const [isConfettiVisible, setIsConfettiVisible] = React.useState(false);
  const [displaySuccessMessage, changeDisplaySuccessMessage] = React.useState(
    false
  );

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValue({
      ...values,
      [name]: {
        ...values[name],
        value,
        hasError: !values[name].validation(value)
      }
    });
  };

  const handleInputChange = val => {
    return /^[^@]+@[^@]+\.[^@]+$/.test(val);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (Object.values(values).some(({ hasError }) => hasError)) {
      setValue({ ...values, isShowError: true });
    } else {
      setIsConfettiVisible(true);
      changeDisplaySuccessMessage(!displaySuccessMessage);
      winATripSave({ source: 'winATrip', ...values });
    }
  };

  const config = {
    angle: 90,
    spread: '360',
    startVelocity: '10',
    elementCount: '45',
    dragFriction: 0.12,
    duration: '2160',
    stagger: 5,
    width: '15px',
    height: '7px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
  };

  return (
    <div
      className={`formToWin-wrapper ${
        !displaySuccessMessage ? '' : 'success-message'
      }`}
    >
      {!displaySuccessMessage && (
        <>
          <form className="formToWin-from" data-subscription-form>
            <div className="submit-block">
              {configurableHeadingText ? (
                <div
                  className="form-heading-rich-text"
                  dangerouslySetInnerHTML={{
                    __html: configurableHeadingText
                  }}
                />
              ) : (
                <>
                  <h4>ENTER THE GIVEAWAY NOW</h4>
                  <p
                    style={{
                      'margin-top': '-15px',
                      'margin-bottom': '20px',
                      'font-size': '12px'
                    }}
                  >
                    (we never spam or sell your data)
                  </p>
                </>
              )}
              <div className="submit-wrapper d-flex">
                <div className="input">
                  <label htmlFor="name">{values.name.label}</label>
                  <input
                    id="name"
                    className="submit-input"
                    type="text"
                    name="name"
                    value={values.name.value}
                    onChange={handleChange}
                    required
                  />
                  {values.isShowError && values.name.hasError === true && (
                    <p className="error-text">{values.name.errorText}</p>
                  )}
                </div>
                <div className="input">
                  <label htmlFor="phone">{values.phone.label}</label>
                  <input
                    id="phone"
                    className="submit-input"
                    text="phone"
                    name="phone"
                    value={values.phone.value}
                    onChange={handleChange}
                    placeholder="Contact Number"
                    required
                  />
                  {values.isShowError && values.phone.hasError === true && (
                    <p className="error-text">{values.phone.errorText}</p>
                  )}
                </div>
                <div className="input">
                  <label htmlFor="email">{values.email.label}</label>
                  <input
                    id="email"
                    className="submit-input"
                    text="email"
                    name="email"
                    value={values.email.value}
                    onChange={handleChange}
                    placeholder="your.email@mail.com"
                    required
                  />
                  {values.isShowError && values.email.hasError === true && (
                    <p className="error-text">{values.email.errorText}</p>
                  )}
                </div>
                <div className="united d-flex">
                  <div className="input">
                    <label htmlFor="originAirport">
                      {values.originAirport.label}
                      <input
                        id="originAirport"
                        className="submit-input"
                        name="originAirport"
                        value={values.originAirport.value}
                        onChange={handleChange}
                        required
                      />
                      {values.isShowError &&
                        values.originAirport.hasError === true && (
                          <p className="error-text">
                            {values.originAirport.errorText}
                          </p>
                        )}
                    </label>
                  </div>
                  <div className="input">
                    <label htmlFor="year">
                      {values.tripsInYear.label}
                      <input
                        id="year"
                        className="submit-input"
                        type="number"
                        name="tripsInYear"
                        value={values.tripsInYear.value}
                        onChange={handleChange}
                        required
                      />
                      {values.isShowError &&
                        values.tripsInYear.hasError === true && (
                          <p className="error-text">
                            {values.tripsInYear.errorText}
                          </p>
                        )}
                    </label>
                  </div>
                </div>
                <div className="btn-submit">
                  <button
                    className="button-search"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Enter Now
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="agree-content">
            <span>By entering, I agree to the</span>
            <Link to="/win-a-trip/terms-conditions">Terms and Conditions</Link>
          </div>
        </>
      )}
      {displaySuccessMessage && (
        <div className="thank-you" data-thank-you="">
          <h3 className="h3">Thank you for entering!</h3>
          <h4 className="text">
            Check out our greatest flight deals
            <a href="https://www.travelopod.com/"> here</a>. Special offer -
            UP-TO $350 off on selected flights
          </h4>
        </div>
      )}
      <div className="confetti-wrapper">
        <Confetti active={isConfettiVisible} config={config} />
      </div>
    </div>
  );
};

export default FormToWin;
