import React from 'react';

import './index.css';

const PromoLogos = () => (
  <div className="promo-logos-wrapper">
    <div className="promo-logos-block d-flex">
      <div className="img-achievements">
        <img
          className="image"
          src="/../../images/promo-logos/fb.jpg"
          alt="best-price-guaranteed"
        />
      </div>
      <div className="img-achievements">
        <img
          className="image"
          src="/../../images/promo-logos/google_customer_reviews.jpg"
          alt="best-price-guaranteed"
        />
      </div>
      <div className="img-achievements">
        <img
          className="image"
          src="/../../images/promo-logos/accredited_business.jpg"
          alt="best-price-guaranteed"
        />
      </div>
    </div>
  </div>
);

export default PromoLogos;
