import React from 'react';
import { marketingUrl } from '../../../configs';
import './index.css';

const GiveawayContent = ({ config }) => {
  const styleFacebookLike = {
    border: 'none',
    width: '70px',
    height: '28px'
  };

  const styleTwitterLike = {
    width: '80px',
    height: '28px'
  };

  const styleFacebookPlugin = {
    border: 'none',
    width: '79px',
    height: '28px'
  };

  const styleTwitterTweet = {
    width: '80px',
    height: '28px'
  };

  return (
    <div className="giveaway-wrapper">
      <div id="thank-you-link" className="only-for-mobile"></div>
      {config && config.header ? (
        <div
          className="header-rich-text"
          dangerouslySetInnerHTML={{ __html: config.header }}
        />
      ) : (
        <>
          <h2>Free Trip to India Giveaway</h2>
          <h4 className="giveaway-text-nospace">(Upto $1000 value)</h4>
        </>
      )}

      {config && config.pre_social_icons_content ? (
        <div
          className="pre-social-icons-content-rich-text"
          dangerouslySetInnerHTML={{ __html: config.pre_social_icons_content }}
        />
      ) : (
        <p>
          Travelopod spots flight deals to help you travel more.
          <br />
          This time, we're giving you one of our deals for free
        </p>
      )}
      <div id="thank-you-link" className="only-for-desktop"></div>
      <div className="cms_plugin-likes">
        <ul className="d-flex">
          {/* <li className="d-flex">
            <a
              href="https://www.facebook.com/Travelopod/"
              style={{
                backgroundColor: 'transparent',
                'text-decoration': 'none',
                color: 'black'
              }}
            >
              <iframe
                allowFullScreen
                frameBorder="0"
                height="1000px"
                name="f17803247cbd81c"
                scrolling="no"
                src="https://www.facebook.com/v2.12/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2Fd_vbiawPdxB.js%3Fversion%3D44%23cb%3Df2b29c7f19b91c%26domain%3Dold.travelopod.com%26origin%3Dhttps%3A%2F%2Fold.travelopod.com%2Ff1fc42f640a187%26relation%3Dparent.parent&amp;container_width=320&amp;href=http%3A%2F%2Fstaging.travelopod.biz%2Fpromo%2Fwin-a-trip&amp;layout=button&amp;locale=en_GB&amp;sdk=joey&amp;share=false&amp;show_faces=false&amp;size=large"
                style={styleFacebookLike}
                title="fb:like Facebook Social Plugin"
                width="1000px"
              ></iframe>
            </a>
            <p className="iframe-text">+1 entry</p>
          </li>
          <li>
            <iframe
              className="twitter-follow-button twitter-follow-button-rendered"
              data-screen-name="travelopod"
              frameBorder="0"
              id="twitter-widget-0"
              scrolling="no"
              src="https://platform.twitter.com/widgets/follow_button.2e9f365dae390394eb8d923cba8c5b11.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;screen_name=TravelopodUS&amp;show_count=false&amp;show_screen_name=false&amp;size=l&amp;time=1553185988739"
              style={styleTwitterLike}
              title="Twitter Follow Button"
            ></iframe>
            <p className="iframe-text">+1 entry</p>
          </li>
          <li>
            <a
              href="https://www.instagram.com/travelopod/"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              title="follow me on instagram"
              rel="noopener"
            >
              <img src="/../../images/instagram-follow.png" alt="instagram" />
              <span>Follow</span>
            </a>
            <p className="iframe-text">+2 entries</p>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Travelopod/"
              style={{
                backgroundColor: 'transparent',
                'text-decoration': 'none',
                color: 'black'
              }}
            >
              <iframe
                allowFullScreen={true}
                frameBorder="0"
                height="1000px"
                name="f5b32130d6f7b8"
                scrolling="no"
                src="https://www.facebook.com/v2.12/plugins/share_button.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2Fd_vbiawPdxB.js%3Fversion%3D44%23cb%3Df3620db8e6a2fd4%26domain%3Dold.travelopod.com%26origin%3Dhttps%3A%2F%2Fold.travelopod.com%2Ff1fc42f640a187%26relation%3Dparent.parent&amp;container_width=320&amp;href=https%3A%2F%2Ftravelopod.com%2Fpromo%2Fwin-a-trip%2F&amp;layout=button&amp;locale=en_GB&amp;mobile_iframe=true&amp;sdk=joey&amp;size=large"
                style={styleFacebookPlugin}
                title="fb:share_button Facebook Social Plugin"
                width="1000px"
              ></iframe>
            </a>
            <p className="iframe-text">+3 entries</p>
          </li> */}
          {/* <li>
            <iframe
              className="twitter-share-button twitter-share-button-rendered twitter-tweet-button"
              data-url="https://www.travelopod.com/win-a-trip/"
              frameBorder="0"
              id="twitter-widget-1"
              scrolling="no"
              src="https://platform.twitter.com/widgets/follow_button.2e9f365dae390394eb8d923cba8c5b11.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;screen_name=TravelopodUS&amp;show_count=false&amp;show_screen_name=false&amp;size=l&amp;time=1553185988739"
              style={styleTwitterTweet}
              title="Twitter Tweet Button"
            ></iframe>
            <p className="iframe-text">+3 entries</p>
          </li> */}
        </ul>
      </div>
      {config && config.post_social_icons_content ? (
        <div
          className="post-social-icons-content-rich-text "
          dangerouslySetInnerHTML={{ __html: config.post_social_icons_content }}
        />
      ) : (
        <div className="giveaway-wrapper-nospace">
          <p>
            We are excited to announce an exciting opportunity for all travel
            enthusiasts out there! As a token of appreciation for your constant
            support, we are giving away a <b>FREE trip to INDIA</b> - one of the
            most captivating countries on the planet!
          </p>
          <p>
            To enter the giveaway, all you have to do is follow our Instagram
            page @travelopod, like our Giveaway post, and tag your friends and
            family in the comments below.
          </p>
          <p>
            The winner of this incredible trip will be announced on June 20th,
            so make sure to keep an eye on our website and social media channels
            for updates.
          </p>
          <p>
            Thank you for your continued support, and we can't wait to share
            this unforgettable journey with one lucky winner!
          </p>
          <p>
            Giveaway ends June15th (full rules{' '}
            <a href={`${marketingUrl}win-a-trip/terms-conditions`}>here</a>)
          </p>
        </div>
      )}
    </div>
  );
};

export default GiveawayContent;
