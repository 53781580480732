import React from 'react';
import WinATripImg from '../Image/WinATripImg';

import './index.css';

const TitleImgBlock = ({ title, filename }) => {
  return (
    <div className="title-img-block">
      <h1>{title}</h1>
      <WinATripImg alt="flight-background" />
    </div>
  );
};

export default TitleImgBlock;
