import React from 'react';

import './index.css';
import GiveawayContent from '../Giveaway/Content/GiveawayContent';
import FormToWin from '../Giveaway/FormToWin/FormToWin';

const WinATripContent = ({ config }) => {
  return (
    <div className="win-a-trip-content">
      <div className="wrapper">
        <div className="giveaway main d-flex">
          <GiveawayContent config={config} />
          <FormToWin configurableHeadingText={config && config.form_heading} />
        </div>
        <div className="wrapper-comments main">
          <h2>Last Winner Testimonial</h2>
          <div className="comment d-flex">
            <div className="img-block">
              <img
                className="image"
                src="/../../images/happy-travel.jpeg"
                alt="happy-travel"
              />
            </div>
            <div className="comment-text">
              {config && config.testimonial ? (
                <div dangerouslySetInnerHTML={{ __html: config.testimonial }} />
              ) : (
                <>
                  <h3>Evelyn and Bill Jacobs</h3>
                  <p>
                    We would like to thank Travelopod for designing such a
                    wonderful and unforgettable trip to Langkawi. It was a great
                    and well-arranged trip and every suggestion from you, right
                    from choosing Berjaya resort or the sunset dinner cruise was
                    simply great. I shall surely book my future trips through
                    you. Everything was well-arranged and truly promised a
                    fun-filled holiday.
                  </p>
                </>
              )}
              <div className="quotes">
                <img
                  className="image"
                  src="/../../images/quotes.png"
                  alt="quotes"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinATripContent;
